<template>
  <div class="page-container">
    <div class="search-wrap">
      <van-dropdown-menu style="flex: 1">
        <van-dropdown-item v-model="queryParams.subtractMonth" :options="option" @change="handleSearch" />
      </van-dropdown-menu>
    </div>

    <van-empty v-if="!list.length" description="暂无数据"></van-empty>
    <van-list
      v-else
      class="list-wrap"
      v-model:loading="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="getData"
    >
      <div v-for="item in list" :key="item.askWorkCode" @click="goDetail(item)">
        <DataBox :data="item" showArrow />
      </div>
    </van-list>
  </div>
</template>
<script>
import { settlementList } from '@/api/serviceCharge'
import DataBox from './components/DataBox.vue'
import moment from 'moment'

export default {
  // name: 'ServiceChargeList',
  components: {
    DataBox
  },
  data() {
    return {
      option: [],
      queryParams: { subtractMonth: 3 },
      page: 1,
      list: [],
      loading: false,
      finished: false
    }
  },
  created() {
    const list = []
    for (const item of [3, 6, 12]) {
      list.push({
        text: `近${item}个月`,
        value: item,
        startDate: moment().subtract(item, 'months').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD')
      })
    }
    this.option = list
  },

  methods: {
    getData() {
      const target = this.option.find((item) => item.value === this.queryParams.subtractMonth)
      const { startDate, endDate } = target
      this.loading = true
      settlementList({
        pageNo: this.page,
        pageSize: 10,
        empPk: this.$route.query.empPk,
        startDate,
        endDate
      })
        .then((res) => {
          if (res.data.success) {
            this.list = [...this.list, ...res.data.data]
            this.page += 1
            if (res.data.data.length < 10) {
              this.finished = true
            }
          } else {
            this.finished = true
          }
        })
        .catch((err) => {
          this.finished = true
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleSearch() {
      this.list = []
      this.page = 1
      this.finished = false
      this.getData()
    },
    goDetail(item) {
      item.isRead = 1
      sessionStorage.setItem('serviceChargeDetail', JSON.stringify(item))
      this.$router.push({
        path: '/serviceChargeDetail',
        query: {
          id: item.id,
          empPk: this.$route.query.empPk
        }
      })
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style lang="less" scoped>
.search-wrap {
  display: flex;
  align-items: center;

  :deep(.van-dropdown-menu__bar) {
    background-color: transparent;
    box-shadow: none;
    .van-dropdown-menu__item {
      flex: 0 1 auto;
      .van-dropdown-menu__title {
        color: #666;
      }
    }
  }
}

.page-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.list-wrap {
  overflow-y: auto;
  height: 0;
  flex: 1;
}
</style>
