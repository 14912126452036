import request from '../utils/request.js'

// 获取服务费列表
export function settlementList(params) {
  return request().get(`/api/con/v1/task/settlement/list`, { params })
}

// 服务费已读状态更新
export function taskSettlementRead(params) {
  return request().get(`/api/con/v1/task/settlement/read`, { params })
}
