<template>
  <div class="item-box">
    <div class="header">
      <div class="title">单号：{{ data.settlementCode }}</div>
      <div>
        <van-badge style="margin-right: 10px" :dot="data.isRead !== 1 && showArrow">
          <span style="visibility: hidden">a</span>
        </van-badge>
        <van-icon v-if="showArrow" name="arrow" />
      </div>
    </div>
    <div class="date">{{ data.startDate }} ～ {{ data.endDate }}</div>
    <div class="comName">{{ data.projectName }}（{{ data.projectCode }}）</div>
    <div class="price"><PriceFormat :price="data.totalServiceFee" /></div>
  </div>
</template>

<script>
import PriceFormat from './PriceFormat.vue'

export default {
  components: {
    PriceFormat
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    showArrow: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="less" scoped>
.item-box {
  margin: 0 8px 12px;
  background-color: #fff;
  border-radius: 8px;
  .header {
    display: flex;
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    background-color: #f2fbff;
    .title {
      font-size: 12px;
      font-weight: 600;
      color: #333;
    }
  }

  .date {
    padding: 16px 16px 0;
    font-size: 16px;
    line-height: 22px;
    color: #666;
  }

  .comName {
    padding: 8px 16px 0;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    color: #21aeff;
  }
}
</style>
