<template>
  <div class="price" v-if="numberData == null">-</div>
  <div class="price" v-else>
    <span class="big">￥{{ numberData.big }}</span>
    <span v-if="numberData.small != null">{{ numberData.small }}</span>
  </div>
</template>

<script>
export default {
  props: {
    price: {
      type: Number,
      default: 0
    }
  },
  computed: {
    numberData() {
      if (this.price == null) {
        return null
      } else {
        const [big, small] = this.price.toString().split('.')
        return {
          big: new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(+big),
          small: small != null ? '.' + small : null
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.price {
  padding: 24px 16px 20px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  color: #ff4d4f;
  .big {
    font-size: 24px;
  }
}
</style>
